import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",
    component: () => import("../views/AboutUsView.vue"),
  },
  {
    path: "/services",
    name: "Servcices",
    component: () => import("../views/ServicesView.vue"),
  },
  {
    path: "/how-works",
    name: "How works",
    component: () => import("../views/HowWorksView.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/HelpView.vue"),
  },
  {
    path: "/make-CV",
    name: "Make CV",
    component: () => import("../views/MakeCVView.vue"),
  },
  {
    path: "/current-offers",
    name: "Current Offers",
    component: () => import("../views/CurrentOffersView.vue"),
  },
  {
    path: "/realized-projects",
    name: "Realized Projects",
    component: () => import("../views/RealizedProjectsView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/offer",
    name: "Offer",
    component: () => import("../views/OfferView.vue"),
  },
  {
    path: "/GDPR",
    name: "GDPR",
    component: () => import("../views/GDPRView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
